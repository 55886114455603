import React from 'react'
import logo from '../../../assets/images/noustrobeta.svg'
import NavProfile from './nav-profile'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TlaIcon from '../../../components/tla-icon'
import PropTypes from 'prop-types'

function Header ({ showMenu }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const user = useSelector(state => state.loginReducer.authUser)
  const items = [
    {
      key: 'assess',
      link: '/feed',
      label: 'Assess'
    },
    {
      label: 'Learn',
      key: 'learn',
      link: '/learn'
    },
    {
      key: 'profile',
      link: '/dashboard/overview/self',
      label: 'Dashboard'
    },
    {
      key: 'nous-gpt',
      link: '/nous-gpt',
      label: 'NousGPT'
    },
    {
      key: 'requests',
      link: '/requests',
      label: 'Requests'
    }
  ]

  // eslint-disable-next-line react/prop-types
  const LinkItem = ({ link, children, active }) => (
        <Link to={link} rel="opener"
              className={`${active ? 'text-primary-700 bg-primary-50' : 'text-gray-700'} hover:text-primary-700 hover:bg-primary-50 rounded-md text-base px-4 py-2.5 font-medium`}>
            {children}
        </Link>
  )

  return (
        <div>
            <div className={'flex items-center bg-white-base mb-[18px] px-2 md:px-[49px] h-[73px]'}>
                <div onClick={() => {
                  navigate('/feed')
                }} className={'cursor-pointer pl-6 md:pl-0'}>
                    <img src={logo} width={130} alt={'Nouscard'} height={'auto'}/>
                </div>
                {
                    showMenu &&
                    <div className="hidden md:block ml-20">
                        <div className={'flex gap-x-1 ml-4 w-full'}>
                            {
                                user?.role === 'user' &&
                                <React.Fragment>
                                    {
                                        items.map(({ key, link, label }) => (
                                            <LinkItem key={key} link={link}
                                                      active={label === 'Dashboard' ? (pathname === link || pathname === '/dashboard/overview/contact') : pathname === link}>
                                                {label}
                                                {
                                                    label === 'Requests' &&
                                                    <React.Fragment>
                                                        &nbsp;
                                                        <span
                                                            className={'bg-error-100 text-error-900 px-2.5 py-0.5 rounded-full'}>
                                                        {user?.userInformation?.totalRequests}
                                                    </span>
                                                    </React.Fragment>
                                                }
                                            </LinkItem>
                                        ))
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                }

                <div className={'flex items-center gap-x-4 ml-auto'}>
                    <a title={'Customer Service'} target={'_blank'}
                       href="https://docs.google.com/forms/d/e/1FAIpQLSfwPVbOwnCE7pxU-8UAKT037SCSHwcmhaH5enYwiiTvE9dK9A/viewform"
                       rel="noreferrer">
                        <TlaIcon name={'customer-service'}/>
                    </a>
                    <a title={'User Guide'} target={'_blank'} href="https://noustro.com/how-it-works/" rel="noreferrer">
                        <TlaIcon name={'user-guide'}/>
                    </a>
                    <div className={'hidden md:block ml-auto'}>
                        <NavProfile/>
                    </div>
                </div>
            </div>
        </div>
  )
}

Header.defaultProps = {
  showMenu: true
}

Header.propTypes = {
  showMenu: PropTypes.bool
}

export default Header
