import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import ChatGPT from '../../../assets/images/icons/gpt.svg'
import TlaImage from '../../../components/tla-image'
import { useSelector } from 'react-redux'
import { BrainIcon } from '../../../components/public-components/nousgpt/brain-icon'
import { HandshakeIcon } from '../../../components/public-components/nousgpt/handshake-icon'

function ChatMessage ({ self, text, expert }) {
  const profile = useSelector(state => state.loginReducer?.authUserInfo)
  return (
        <div className={`flex ${self ? 'mb-4' : 'flex-row-reverse items-start'} gap-3`}>
            {
                self
                  ? <React.Fragment>
                      <TlaImage photo={profile?.userInformation?.photo}/>
                        <div className={'nousgpt-user-msg'}>
                            <p className={'text-base text-gray-900'}>
                                {text}
                            </p>
                        </div>
                    </React.Fragment>
                  : (text?.behavioral || text?.action)
                      ? (<div>
                            {
                                text?.behavioral &&
                                <div className={'flex items-start gap-3'}>
                                    <div className={'nousgpt-ass-response mb-4'}>
                                        <p className={'text-base font-bold text-white-base'}>Behavioral Advice:</p>
                                        {
                                            text?.behavioral?.map((item, index) => (
                                                <p key={index}
                                                   className={'text-base font-bold text-white-base mt-4 leading-[30px]'}>
                                                    {item?.skill}:&nbsp;
                                                    <span className={'font-normal'}>{item?.advice}</span>
                                                </p>
                                            ))
                                        }
                                    </div>
                                    {/* <img src={ChatGPT} alt={'chat-gpt'}/> */}
                                    {expert === 'relationships' ? <BrainIcon className='w-11 h-11' fill='#2ED3B7' /> : <HandshakeIcon className='w-11 h-11' fill='#2ED3B7' />}
                                </div>
                            }
                            {
                                text?.action &&
                                <div className={'flex items-start gap-3'}>
                                    <div className={'nousgpt-ass-response mb-4'}>
                                        <p className={'text-base font-bold text-white-base'}>Action Advice:</p>
                                        {
                                            text?.action?.map((item, index) => (
                                                <p key={index}
                                                   className={'text-base font-bold text-white-base mt-4 leading-[30px]'}>
                                                    {item?.advice_title}:&nbsp;
                                                    <span className={'font-normal'}>{item?.advice}</span>
                                                </p>
                                            ))
                                        }
                                    </div>
                                    {expert === 'relationships' ? <BrainIcon className='w-11 h-11' fill='#2ED3B7' /> : <HandshakeIcon className='w-11 h-11' fill='#2ED3B7' />}
                                </div>
                            }
                        </div>)
                      : <div className={'flex items-start gap-3'}>
                            <div className={'nousgpt-ass-response mb-4'}>
                                <p className={'text-base font-normal text-white-base mt-4 leading-[30px]'}>
                                    {text.message}
                                </p>
                            </div>
                            {expert === 'relationships' ? <BrainIcon className='w-11 h-11' fill='#2ED3B7' /> : <HandshakeIcon className='w-11 h-11' fill='#2ED3B7' />}
                        </div>
            }
        </div>
  )
}

ChatMessage.defaultProps = {
  self: false,
  expert: 'soft_skills',
  text: ''
}

ChatMessage.propTypes = {
  self: PropTypes.bool,
  expert: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default ChatMessage
