import api from '../../utils/api'
import sessionStorage from 'redux-persist/lib/storage/session'
import { authenticate, getAssessmentQuestions, getUserAssessmentQuestions, getProfile, removeAuth, updateProfile } from './ActionCreators'

/**
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAuthentication = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().post('/auth/login', data).then((response) => {
      dispatch(authenticate(response.data))
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleLogout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get('/auth/logout').then(() => {
      sessionStorage.removeItem('persist:root')
      dispatch(removeAuth())
      resolve()
    }).catch((err) => {
      reject(err)
    })
  })
}

// export const handleGetAssessmentQuestions = () => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     api().get('/question/feedRequest').then((res) => {
//       dispatch(getAssessmentQuestions(res.data))
//       resolve(res)
//     }).catch((err) => {
//       reject(err)
//     })
//   })
// }

export const handleGetAssessmentQuestions = (giverId, receiverId, contactId = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let data = {}
    if (contactId) {
      data = {
        contactId: +contactId
      }
    } else {
      data = {
        giverId, receiverId
      }
    }
    api().post('/assessment/questions', data).then((res) => {
      dispatch(getAssessmentQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleGetUserAssessmentQuestions = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get('/question/self-feedRequest').then((res) => {
      dispatch(getUserAssessmentQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleChangePassword = (data) => () => {
  return new Promise((resolve, reject) => {
    api().patch(`/auth/changepassword/${data.id}`, data).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleGetProfile = () => dispatch => {
  return new Promise((resolve, reject) => {
    api().get('/users/me').then((res) => {
      dispatch(getProfile(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleUpdateProfile = (userId, data) => dispatch => {
  return new Promise((resolve, reject) => {
    api('multipart/form-data').put(`/users/updateuser/${userId}`, data).then((response) => {
      dispatch(updateProfile(response.data))
      resolve(response)
    }).catch((err) => {
      console.log(data)
      reject(err)
    })
  })
}

export const handleUploadDp = (data) => dispatch => {
  return new Promise((resolve, reject) => {
    api().post(`/profile/${data.id}/upload-dp`, data).then((response) => {
      dispatch(authenticate(response.data))
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 *
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleRegistration = (data) => (dispatch) => {
  data.mobileNumber = data.mobileNumber.replace(/\+/g, '')
  return new Promise((resolve, reject) => {
    api().post('/auth/register', data).then((res) => {
      dispatch(authenticate(res.data))
      resolve(res.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleResetPassword = (data, token) => () => {
  return new Promise((resolve, reject) => {
    api().patch(`/auth/resetpassword/${token}`, data).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleForgotPassword = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/auth/forgotpassword', data).then((res) => {
      // dispatch(authenticate(response.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleEmailVerification = (token) => () => {
  return new Promise((resolve, reject) => {
    api().get(`/auth/verifyemail/${token}`).then(() => {
      resolve()
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleResendEmailVerification = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/auth/email/verify/resend')
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
