import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatDate } from '../../../utils'
import ResponsiveText from './responsive-text'

function QuizResult ({ result, extraClasses, bulk = false }) {
  const { tierJson } = result
  return (
    <div className={`bg-white-base rounded-lg mx-auto w-full ${extraClasses !== '' ? extraClasses : 'md:w-[861px]'}`}>
      <div>
        {
          !bulk &&
          <div className="quiz-header py-4 px-6 flex flex-col gap-2 border-b">
            <h1 className='text-lg font-medium text-gray-900'>Your Result</h1>
          </div>
        }
        <div className={`quiz-body p-5 ${bulk ? 'py-3' : 'pt-8'}`}>
          <div className="flex justify-between flex-col items-start md:items-center md:flex-row mb-3">
            <h2 className="font-semibold text-lg order-2 md:order-1">{result.quizForm?.titleScoreCard}</h2>
            <span className="text-sm order-1 md:order-2">{formatDate(result.date, 'MM-DD-YYYY')}</span>
          </div>
          <h3 className='font-semibold text-success-500'>{tierJson?.title}</h3>
          <pre className='whitespace-pre-wrap break-words overflow-auto py-4 text-sm md:text-base'>
            {
              bulk
                ? <ResponsiveText text={tierJson.description} buttonClasses={'text-sm underline text-gray-500 cursor-pointer'} />
                : tierJson?.description
            }
          </pre>
        </div>
        {
          !bulk &&
          <div className="flex justify-center gap-5 p-5">
            <Link to='/feed' className={'btn border rounded-lg px-5 py-3'}>
              Back to feed page
            </Link>
          </div>
        }
      </div>
    </div>
  )
}

QuizResult.propTypes = {
  result: PropTypes.object,
  bulk: PropTypes.bool,
  extraClasses: PropTypes.object
}

export default QuizResult
