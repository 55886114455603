import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleDeleteChat, handleUpdateChatTitle } from '../../actions/user/chat/Action'
import { Button, Input, Spin } from 'antd'
import { hashCode } from '../../utils'
import { GoPencil } from 'react-icons/go'
import TlaConfirm from '../../components/tla-confirm'
import { BrainIcon } from '../../components/public-components/nousgpt/brain-icon'
import { HandshakeIcon } from '../../components/public-components/nousgpt/handshake-icon'

function SingleChat ({ chat, deleteChat, updateTitle, deleteSuccessFunc }) {
  const { state } = useLocation()
  const [updating, setUpdating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(chat.chatTitle)
  const inputRef = useRef(null)
  useEffect(() => {
    if (updating && inputRef.current) {
      inputRef.current.focus()
    }
  }, [updating])
  const updateNewTitle = () => {
    setLoading(true)
    updateTitle(name, chat.chatId).then(res => {
      setUpdating(false)
      setLoading(false)
    })
  }
  const handleStopPropagation = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  return (
      <Spin spinning={loading}>
        <div key={chat?.id} className={`single-chat flex items-center ${state?.chatId === chat?.chatId ? 'bg-primary-50' : ''}`}>
          <Link
            state={{
              chatId: chat?.chatId,
              date: chat?.date
            }}
            to={`/nous-gpt/chat/${hashCode(chat?.chatId + 'amos')}/detail`}
            className="w-full"
          >
            <div className="flex items-center py-4 px-4 gap-x-3">
              <div className="flex items-center justify-between w-full relative">
                {!updating
                  ? (
                      <div className="flex items-center gap-2">
                        <div className="w-12">
                          {chat.expert === 'relationships' ? <BrainIcon className='w-8 h-8' fill='#2ED3B7' /> : <HandshakeIcon className='w-8 h-8' fill='#2ED3B7' />}
                        </div>
                        <p className='shrink'>{name}</p>
                      </div>
                    )
                  : (
                      <Input ref={inputRef} className="w-full border-0 bg-transparent focus-visible:outline-none focus-visible:border-0 grow" value={name} onChange={(e) => setName(e.target.value)} onClick={handleStopPropagation} />
                    )}
              </div>
            </div>
          </Link>
          {
            updating
              ? <div>
                  <Button onClick={updateNewTitle} className='btn btn-primary mx-2' size='small'>Save</Button>
                </div>
              : <div className="chat-icons flex items-center gap-4 px-1">
                  <GoPencil onClick={() => setUpdating(!updating)} className="text-gray-500 cursor-pointer" />
                  <TlaConfirm title={'Delete chat'}
                    fullText={'Do you really want to delete this chat?'}
                    callBack={() => {
                      setLoading(true)
                      deleteChat(chat.chatId).then(res => {
                        setLoading(false)
                        deleteSuccessFunc(chat.chatId)
                      })
                    }} />
                </div>
          }
        </div>
      </Spin>
  )
}

SingleChat.propTypes = {
  chat: PropTypes.object.isRequired,
  updateTitle: PropTypes.func.isRequired,
  deleteSuccessFunc: PropTypes.func.isRequired,
  deleteChat: PropTypes.func.isRequired
}
const mapDispatchToProps = (dispatch) => ({
  updateTitle: (title, id) => dispatch(handleUpdateChatTitle(title, id)),
  deleteChat: (id) => dispatch(handleDeleteChat(id))
})

export default connect(null, mapDispatchToProps)(SingleChat)
