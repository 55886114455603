import React, { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Outlet } from 'react-router-dom'
import DashboardNavs from './dashboard-navs'
import PropTypes from 'prop-types'
import { handleGetProfile } from '../../actions/authenticate/Actions'
import { connect } from 'react-redux'
import SidebarForm from './layout/sidebar-form'

const GlobalStyles = createGlobalStyle`
  .circle-container {
    margin: 0 auto;
    display: grid;
  }

  .circle-inner {
    padding: 5px 25px;
  }

  .description {
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--Gray-500);
  }
`

function Dashboard ({ getProfile }) {
  useEffect(() => {
    getProfile()
  }, [])
  return (
        <React.Fragment>
            <div className={'hidden md:block xl:hidden'}>
                <SidebarForm col={'row'}/>
            </div>
            <div className={'bg-white-base rounded-lg p-[13px]'}>
                <GlobalStyles/>
                <div>
                    <h1 className={'font-medium text-3xl leading-[38px] text-gray-900'}>Your Skills & Personality</h1>
                    {/* <p className={'font-normal text-base leading-6 text-gray-500'}>Your current skill score</p> */}
                </div>
                <div className={'mt-[25px]'}>
                    <DashboardNavs/>
                </div>
                <Outlet/>
            </div>
        </React.Fragment>
  )
}

Dashboard.propTypes = {
  getProfile: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(handleGetProfile())
})

export default connect(null, mapDispatchToProps)(Dashboard)
