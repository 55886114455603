import React from 'react'
import { Space } from 'antd'
import { BsFillCircleFill } from 'react-icons/bs'
import LearnMore from '../../../../components/learn-more'

function CompetencyHead () {
  return (
        <div>
            <div className={'border-b pb-0.5'}>
                <p className={'text-gray-500 font-normal text-sm leading-[11px]'}>Top 10</p>
                <h6 className={'text-gray-900 text-lg font-medium'}>Your Skills</h6>
            </div>
            <div className={'mt-5 mb-6 mx-8 ml-auto w-fit text-right'}>
                <Space size={'large'}>
                    <p className={'description'}><BsFillCircleFill style={{ fontSize: 8, color: 'var(--Blue-800)' }}/>&nbsp;&nbsp;Absolute</p>
                    <p className={'description'}>
                        <BsFillCircleFill style={{ fontSize: 8, color: 'var(--Blue-400)' }}/>&nbsp;&nbsp;Normalized
                    </p>
                </Space>
                <LearnMore/>
            </div>
        </div>
  )
}

export default CompetencyHead
