import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Spin } from 'antd'

function SingleNousQuiz ({ quiz, showFooter = false, changeQuiz, quizNo, totalQuizzes }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const setQuiz = () => {
    dispatch({ type: 'SET_SINGLE_QUIZ', payload: quiz.id })
  }
  useEffect(() => {
    setLoading(false)
  }, [])
  if (!quiz) {
    return (
      <div className='bg-white-base p-4 mb-3 rounded-xl w-full flex justify-center'>
        <Spin spinning={loading}></Spin>
      </div>
    )
  }
  return (
    <div className='bg-white-base py-4 mb-3 rounded-xl w-full'>
      {/* quiz body */}
      <div className="px-4">
        <h3 className="text-green-500 mb-3 font-semibold">NousQuiz</h3>
        <h3 className="font-semibold text-lg">{quiz.titleQuizForm}</h3>
        <p>
          {quiz.description}
        </p>
        <div className="text-right my-1 pb-3 mt-4">
          <Link to={`/quiz/${quiz.id}/questions`} onClick={setQuiz} state={quiz} className='bg-[#1570EF] text-white-base rounded-lg px-4 py-2 hover:text-white-base hover:bg-blue-600'>Start Now</Link>
        </div>
      </div>
      {/* quiz footer */}
      {
        showFooter &&
        <div className="border-t border-gray-300 mt-2 flex justify-between px-5 pt-2">
          <Link to={'/nous-quizzes'} className="text-gray-300 underline">View All</Link>
          <div className='inline-flex gap-5 items-center'>
            <Button className='border-0 bg-white-base' onClick={() => changeQuiz(quizNo - 1)} disabled={quizNo <= 0}><BsChevronLeft className='text-gray-300 cursor-pointer' /></Button>
            <Button className='border-0 bg-white-base' onClick={() => changeQuiz(quizNo + 1)} disabled={(quizNo + 1) >= totalQuizzes}><BsChevronRight className='text-gray-300 cursor-pointer' /></Button>
          </div>
        </div>
      }
    </div>
  )
}

SingleNousQuiz.propTypes = {
  showFooter: PropTypes.bool,
  quizNo: PropTypes.number,
  totalQuizzes: PropTypes.number,
  changeQuiz: PropTypes.func,
  quiz: PropTypes.object
}

export default SingleNousQuiz
