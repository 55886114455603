import { Route } from 'react-router-dom'
import React from 'react'
import PublicHeader from '../public-components/public-header'
import PublicCourses from '../../pages/public-pages/public-courses'
import PublicSingleCourse from '../../pages/public-pages/public-single-course'

export default [
    <Route key={ 'public-courses' } path={ 'public' } element={ <PublicHeader/> }>
        <Route index element={ <PublicCourses/> } path='courses'/>
        <Route element={ <PublicSingleCourse/> } path='courses/:courseId'/>
    </Route>
]
