import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getUserFeed (payload, reset) {
  return {
    type: Types.GET_USER_FEED,
    payload,
    reset
  }
}

export function getUserContentPosts (payload, refresh) {
  return {
    type: Types.GET_USER_CONTENT_POSTS,
    payload,
    refresh
  }
}

export function getUserQuizzes (payload, refresh) {
  return {
    type: Types.GET_USER_QUIZZES,
    payload,
    refresh
  }
}

export function getQuizQuestions (payload) {
  return {
    type: Types.GET_QUIZ_QUESTIONS,
    payload
  }
}

export function setSingleQuiz (payload) {
  return {
    type: Types.SET_SINGLE_QUIZ,
    payload
  }
}

export function quizSubmission (payload) {
  return {
    type: Types.QUIZ_SUBMISSION,
    payload
  }
}

export function getUserQuizResults (payload) {
  return {
    type: Types.QUIZ_RESULTS,
    payload
  }
}

export function getUserCourses (payload, refresh) {
  return {
    type: Types.GET_USER_COURSES,
    payload,
    refresh
  }
}

export function getUserCourseDetails (payload) {
  return {
    type: Types.GET_USER_COURSE_DETAILS,
    payload
  }
}

export function courseEnrollment (payload) {
  return {
    type: Types.COURSE_ENROLLMENT,
    payload
  }
}

export function getUserModuleLesson (payload) {
  return {
    type: Types.GET_USER_LESSON,
    payload
  }
}

export function getUserModuleProgress (payload) {
  return {
    type: Types.GET_USER_MODULE_PROGRESS,
    payload
  }
}

export function updateUserLessonProgress (payload) {
  return {
    type: Types.UPDATE_USER_LESSON_PROGRESS,
    payload
  }
}

export function questionSubmission (payload) {
  return {
    type: Types.QUESTION_SUBMISSION,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getSkills (payload) {
  return {
    type: Types.GET_SKILLS,
    payload
  }
}

export function toggleSkill (payload) {
  return {
    type: Types.TOGGLE_SKILL,
    payload
  }
}

export function updateFeed (payload) {
  return {
    type: Types.UPDATE_FEED,
    payload
  }
}

export function getEmotions (payload) {
  return {
    type: Types.GET_EMOTIONS,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getProgressOvertime (payload) {
  return {
    type: Types.GET_PROGRESS_OVERTIME,
    payload
  }
}

export function removeFeed (id) {
  return {
    type: Types.REMOVE_FEED,
    id
  }
}

export function sendInvitation (payload) {
  return {
    type: Types.SET_INVITATION_CODE,
    payload
  }
}

export function validateOrg (payload) {
  return {
    type: Types.VALIDATE_ORG,
    payload
  }
}

export function selectOrg (payload) {
  return {
    type: Types.SELECT_ORG,
    payload
  }
}
