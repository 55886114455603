import React, { useEffect } from 'react'
import './App.css'
import { PublicRoutes } from './components/routes/public-routes'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { AppRoutes } from './components/routes'
import { handleLogout } from './actions/authenticate/Actions'
import { getCookie } from './utils'
import { useLocation, useNavigate } from 'react-router-dom'

function App ({ authenticated, authUser }) {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const location = useLocation()
  const fullPathWithParameters = location.pathname + location.search
  const navigate = useNavigate()
  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === 'initLogout' && e.newValue === 'true') {
        dispatch(handleLogout())
      } else {
        const data = getCookie('authData') ? JSON.parse(getCookie('authData')) : {}
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            user: {
              ...data?.authUser,
              verified: data?.authenticated
            },
            access_token: data?.authToken,
            assessmentQuestions: {}
          }
        })
        if (pathname === '/sign-in') {
          navigate('/')
        } else {
          navigate(pathname)
        }
      }
    })

    return () => {
      window.removeEventListener('storage', this)
    }
  }, [dispatch])

  useEffect(() => {
    const out = localStorage.getItem('initLogout')
    if (out && out === 'false') {
      const data = getCookie('authData') ? JSON.parse(getCookie('authData')) : {}
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          user: {
            ...data?.authUser,
            verified: data?.authenticated
          },
          access_token: data?.authToken,
          assessmentQuestions: {}
        }
      })
      if (pathname === '/sign-in') {
        navigate('/')
      } else {
        navigate(fullPathWithParameters)
      }
    }
  }, [])
  return (
    <div className={'bg-white-base'}>
        {authenticated ? <AppRoutes userType={authUser?.role}/> : <PublicRoutes/>}
    </div>
  )
}

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    authUser: state.loginReducer.authUser
  }
}

export default connect(mapStateToProps)(App)
