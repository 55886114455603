import React from 'react'
import Header from './header'
import PropTypes from 'prop-types'
import { Outlet, useLocation } from 'react-router-dom'
import AppSidebar from './sidebar/app-sidebar'
import { HeaderMenus } from '../../../utils/menu-items'
import MobileMenu from './mobile-menu'
import { Affix } from 'antd'
import MobileProfile from '../../../components/mobile-profile'
import { connect } from 'react-redux'

function CourseLayout () {
  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation()

  // eslint-disable-next-line no-unused-vars
  const profilePaths = ['/dashboard/overview/contact', '/dashboard/overview/self']

  return (
        <div>
            <Affix offsetTop={ 0 }>
                <div className="bg-white-base">
                    <div className={ 'w-full max-w-screen-2xl mx-auto overflow-x-hidden' }>
                        <Header menuItems={ HeaderMenus }/>
                    </div>
                </div>
            </Affix>
            <div className={ 'bg-white-50' }>
                <div className={ 'w-full max-w-screen-2xl mx-auto overflow-x-hidden bg-white-50 py-4' }>
                    {
                        profilePaths.includes(pathname) &&
                        <MobileProfile/>
                    }
                    <div className={ 'flex gap-x-3 justify-between mt-0 mx-2 md:mx-8 relative' }>
                        <AppSidebar/>
                        {/* <div className={'mb-5 w-full md:min-w-[720px] 2xl:max-w-[1290px] flex-1'}> */ }
                        <div className={ 'mb-5 w-full md:min-w-[720px] 2xl:max-w-[1540px] flex-1' }>
                            <Outlet/>
                        </div>
                    </div>
                    <MobileMenu/>
                </div>
            </div>
        </div>
  )
}

CourseLayout.propTypes = {
  children: PropTypes.any
}

export default connect(null, null)(CourseLayout)
