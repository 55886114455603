import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../dashboard/layout/header'
import { HeaderMenus, MobileMenus } from '../../../utils/menu-items'
import { Outlet } from 'react-router-dom'
import MobileMenu from '../../dashboard/layout/mobile-menu'
import SelfHeader from '../../dashboard/layout/self-header'

// eslint-disable-next-line react/prop-types
function FeedbackLayout ({ first }) {
  return (
    <div>
      <div className="bg-white-base">
        <div className={'max-w-screen-2xl mx-auto border-b md:border-none'}>
            {first ? <SelfHeader menuItems={HeaderMenus}/> : <Header menuItems={HeaderMenus}/>}
        </div>
      </div>
      <div className="bg-white-50 py-4">
        <div className={'max-w-screen-2xl mx-auto'}>
            <Outlet/>
            <div className={'block md:hidden fixed pr-4'}>
                <MobileMenu fontSize={16} menuItems={MobileMenus}/>
            </div>
        </div>
      </div>
    </div>
  )
}

FeedbackLayout.propTypes = {
  children: PropTypes.any
}

export default FeedbackLayout
