export const SidebarMenus = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    icon: 'chart'
  },
  {
    title: 'Jobs Queries',
    link: '/quotes',
    icon: 'brief-case'
  },
  {
    title: 'Job Applications',
    link: '/job-applications',
    icon: 'file'
  }
]
export const AdminSidebarMenus = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    children: [],
    permissions: [],
    icon: 'chart'
  },
  {
    title: 'Users',
    link: '/users',
    children: [],
    permissions: [],
    icon: 'users'
  },
  {
    title: 'Organization',
    link: '/organizations',
    children: [],
    permissions: [],
    icon: 'users'
  },
  {
    title: 'Platform Assessment',
    icon: 'contentPost',
    children: [
      {
        title: 'Posts',
        link: '/posts',
        children: [],
        permissions: []
        // icon: 'file'
      },
      {
        title: 'Content Posts',
        link: '/content-posts',
        children: [],
        permissions: []
        // icon: 'contentPost'
      },
      {
        title: 'Questions',
        link: '/questions',
        children: [],
        permissions: []
        // icon: 'questions'
      },
      {
        title: 'Skills',
        link: '/skills',
        children: [],
        permissions: []
        // icon: 'skills'
      },
      {
        title: 'Personality',
        link: '/personalities',
        children: [],
        permissions: []
        // icon: 'personality'
      },
      {
        title: 'Skill Relationships',
        link: '/skill-relationships',
        children: [],
        permissions: []
        // icon: 'skills'
      }
    ]
  },
  {
    title: 'Courses',
    children: [
      {
        title: 'Modules',
        link: '/modules'
      },
      {
        title: 'Lessons',
        link: '/lessons'
      },
      {
        title: 'Lesson Questions',
        link: '/lesson-questions'
      },
      {
        title: 'Module Lessons',
        link: '/module-lessons'
      },
      {
        title: 'Course Categories',
        link: '/course-categories'
      },
      {
        title: 'Courses',
        link: '/courses'
      },
      {
        title: 'Course Modules',
        link: '/course-modules'
      }
    ],
    permissions: [],
    icon: 'lesson'
  },
  {
    title: 'Quizzes',
    children: [
      {
        title: 'Forms',
        link: '/quiz-forms'
      },
      {
        title: 'Questions',
        link: '/quiz-questions'
      },
      {
        title: 'Form Questions Link',
        link: '/quiz-form-questions'
      }
    ],
    permissions: [],
    icon: 'quiz'
  },
  {
    title: 'Settings',
    link: '/settings',
    children: [],
    permissions: [],
    icon: 'settings'
  }
]

export const ProfileMenus = [
  {
    title: 'Profile',
    link: '/profile',
    children: [],
    permissions: [],
    icon: 'profile'
  },
  {
    title: 'Public Profile',
    link: '/public-profile-settings',
    children: [],
    permissions: [],
    icon: 'public-profile'
  },
  {
    title: 'Organizations',
    link: '/organizations',
    children: [],
    permissions: [],
    icon: 'organizations'
  },
  {
    title: 'Settings',
    link: '/settings',
    children: [],
    permissions: [],
    icon: 'settings'
  }
]

export const HeaderMenus = [
  {
    title: 'feed',
    link: '/feed',
    children: [],
    permissions: [],
    icon: 'home'
  },
  {
    title: 'Learn',
    link: '/learn',
    children: [],
    permissions: [],
    icon: 'home'
  },
  {
    title: 'Dashboard',
    link: '/dashboard/overview/self',
    children: [],
    permissions: [],
    icon: 'dashboard'
  },
  {
    title: 'NousGPT',
    link: '/nous-gpt',
    children: [],
    permissions: [],
    icon: 'nous-gpt'
  },

  {
    title: 'Contacts',
    link: '/contacts',
    children: [],
    permissions: [],
    icon: 'contacts'
  },
  {
    title: 'Requests',
    link: '/requests',
    children: [],
    permissions: [],
    icon: 'requests'
  },
  {
    title: 'Courses',
    link: '/courses',
    icon: 'courses'
  }
]
export const MobileMenus = [
  {
    title: 'Home',
    link: '/feed',
    children: [],
    permissions: [],
    icon: 'home'
  },
  {
    title: 'Dashboard',
    link: '/dashboard',
    children: [],
    permissions: [],
    icon: 'dashboard'
  },
  {
    title: 'Contacts',
    link: '/contacts',
    children: [],
    permissions: [],
    icon: 'contacts'
  },
  {
    title: 'Logout',
    link: '/profile',
    children: [],
    permissions: [],
    icon: 'requests'
  },
  {
    title: 'Settings',
    link: '/settings',
    children: [],
    permissions: [],
    icon: 'requests'
  },
  {
    title: 'My Account',
    link: '/profile',
    children: [],
    permissions: [],
    icon: 'requests'
  },
  {
    title: 'Requests',
    link: '/requests',
    children: [],
    permissions: [],
    icon: 'requests'
  },
  {
    title: 'NousGPT',
    link: '/nous-gpt',
    children: [],
    permissions: [],
    icon: 'nous-gpt'
  }
]
