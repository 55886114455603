import React, { useState } from 'react'
import { Divider, Dropdown, theme } from 'antd'
import { RiUser6Line } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import TlaImage from '../tla-image'
import { selectOrg } from '../../actions/user/common/ActionCreator'
import Logout from './logout'
import ProfileInfo from './profile-info'
import OrgLogo from './org-logo'
import TlaIcon from '../tla-icon'

const NavProfile = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const profile = useSelector(state => state.loginReducer?.authUserInfo)

  const { useToken } = theme
  const { token } = useToken()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingBottom: 10
  }

  const menuStyle = {
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 10
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goToOrg = (org) => {
    dispatch(selectOrg(org))
    navigate('/' + org.organization.name + '/users')
  }

  const userOrgs = profile?.userOrg?.reduce((orgs, org) => {
    if (org.isAdmin === true) {
      orgs.push({
        key: `org-${org.id}`,
        label: (<div className={'ml-2 hidden md:block'} onClick={() => {
          goToOrg(org)
        }}>{org?.organization?.name}</div>),
        icon: <OrgLogo logo={org?.organization?.logo} altText={org?.organization?.name}/>
      })
    }

    return orgs
  }, [])

  // eslint-disable-next-line no-unused-vars
  let userItems = [
    {
      className: 'border-t-0 border-t !rounded-none',
      key: 'nous-quizzes',
      label: (<Link to={'/nous-quizzes'}>Nous Quizzes</Link>),
      icon: <div className={'hidden md:block h-4 w-4'}><TlaIcon name={'nous-quiz'}/></div>
    },
    {
      key: 'account',
      label: (<Link to={'/profile'}>Account Settings</Link>),
      icon: <RiUser6Line className={'hidden md:block h-4 w-4'}/>
    }
  ]
  if (userOrgs?.length) {
    userItems = [...userOrgs, ...userItems]
  }
  userItems = [...userItems]
  let items = []

  items = profile.role === 'user' ? [...userItems, ...items] : items

  return (
        <Dropdown menu={{ items }}
                  dropdownRender={(menu) => (
                      <div style={contentStyle}>
                          {profile.role === 'user' && <ProfileInfo showTitle={userOrgs?.length > 0}/>}
                          <Divider style={{ margin: 0 }}/>
                          { React.cloneElement(menu, { style: menuStyle })}
                          <hr className={'mx-2.5 mb-1'}/>
                          <Logout key={'logout'} setLoading={setLoading}/>
                      </div>
                  )}>
            <a onClick={(e) => e.preventDefault()}
               className={'border min-w-[40px] h-10 rounded-full flex items-center justify-center cursor-pointer'}>
                {loading ? <LoadingOutlined/> : <TlaImage photo={profile?.userInformation?.photo}/>}
            </a>
        </Dropdown>
  )
}

export default NavProfile
