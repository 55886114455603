import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FiBookOpen, FiMenu } from 'react-icons/fi'
import { Dropdown, theme } from 'antd'
import { handleLogout } from '../../../actions/authenticate/Actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { selectOrg } from '../../../actions/user/common/ActionCreator'
import TlaIcon from '../../../components/tla-icon'
import { CiChat1 } from 'react-icons/ci'

function MobileMenu ({ logout }) {
  const user = useSelector(state => state.loginReducer.authUser)
  // const profile = useSelector(state => state.loginReducer?.authUserInfo)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const initiateLogout = () => {
  //   setLoading(true)
  //   logout().then(() => {
  //     window.location.replace('/sign-in')
  //     setLoading(false)
  //     return false
  //   })
  // }
  // eslint-disable-next-line no-unused-vars
  const goToOrg = (org) => {
    dispatch(selectOrg(org))
    navigate('/' + org.organization.name)
  }

  let items = [
    {
      key: 'nous-gpt',
      label: (<Link to={'/nous-gpt'}>NousGPT</Link>)
    },
    {
      key: 'courses',
      label: (<Link to={'/courses'}>Courses</Link>)
    }
  ]

  items = [...items]
  // eslint-disable-next-line react/prop-types
  const LinkItem = ({ link, children, active }) => (
        <Link to={link}
              className={`${active ? 'text-primary-700' : 'text-gray-500'} flex items-center flex-col text-xs gap-1` +
                  ' font-medium'}>
            {children}
        </Link>
  )

  const { useToken } = theme
  const { token } = useToken()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingBottom: 10,
    width: 200
  }

  const menuStyle = {
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 12
  }
  return (
        <div className={'block md:hidden relative mt-20'}>
            <div className={'bg-white-base fixed bottom-0 left-0 right-0 z-[9999] border-t px-4'}>
                <div className={'flex items-center justify-between h-[62px] mx-5 mobile-menu-items'}>
                    <LinkItem link={'/dashboard/overview/self'} active={pathname === '/dashboard/overview/self'}>
                        <TlaIcon name={'profile'} className={`${pathname === '/dashboard/overview/self' ? 'text-primary-700' : 'text-gray-500'}`} size={22}/>
                        Profile
                    </LinkItem>
                    <LinkItem link={'/feed'} active={pathname === '/feed'}>
                        <TlaIcon name={'assess'} className={`${pathname === '/feed' ? 'text-primary-700' : 'text-gray-500'}`} size={22}/>
                        Feed
                    </LinkItem>
                    <LinkItem link={'/nous-gpt'} active={pathname === '/nous-gpt'}>
                        <CiChat1 size={22} className={`${pathname === '/nous-gpt' ? 'text-primary-700' : 'text-gray-500'}`} />
                        NousGpt
                    </LinkItem>
                    <LinkItem link={'/requests'} active={pathname === '/requests'}>
                      <div className='relative flex justify-center items-center flex-col'>
                        <TlaIcon name={'requests'} className={`mb-1.5 ${pathname === '/requests' ? 'text-primary-700' : 'text-gray-500'}`} size={22}/>
                        <p>Requests</p>
                        <span className={'bg-error-100 text-error-900 px-2 py-0.5 rounded-full absolute right-0 -top-2'}>{user?.userInformation?.totalRequests}</span>
                      </div>
                    </LinkItem>
                    <LinkItem link={'/courses'} active={pathname === '/courses'}>
                        <FiBookOpen size={22} className={`${pathname === '/courses' ? 'text-primary-700' : 'text-gray-500'}`} />
                        Courses
                    </LinkItem>
                    <Dropdown className='hidden' menu={{ items }}
                              dropdownRender={(menu) => (
                                  <div style={contentStyle}>
                                      { React.cloneElement(menu, { style: menuStyle })}
                                  </div>
                              )}>
                    <span className={'flex items-center flex-col text-xs gap-1 text-gray-500 font-medium'}>
                        <FiMenu className={'text-gray-500'} size={22}/>
                        More
                    </span>
                    </Dropdown>
                </div>
            </div>
        </div>
  )
}

MobileMenu.defaultProps = {
  menuItems: [],
  fontSize: 12
}

MobileMenu.propTypes = {
  logout: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(handleLogout())
  }
}
export default connect(null, mapDispatchToProps)(MobileMenu)
