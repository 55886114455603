import React, { useState, useRef } from 'react'

// eslint-disable-next-line react/prop-types
const PinInput = ({ length, onComplete }) => {
  const [pin, setPin] = useState(Array(length).fill(''))
  const inputs = useRef([])

  const handleChange = (e, index) => {
    const value = e.target.value
    if (/^[0-9]$/.test(value) || value === '') {
      const newPin = [...pin]
      newPin[index] = value
      setPin(newPin)

      if (value && index < length - 1) {
        (inputs.current[index + 1]).focus()
      }
      if (newPin.every(v => v !== '')) {
        onComplete(newPin.join(''))
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      (inputs.current[index - 1]).focus()
    }
  }

  return (
        <div>
            {Array(length).fill(0).map((_, i) => (
                <input
                    className={'rounded-xl font-medium text-primary-400 text-5xl w-20 h-20 border border-primary-300 focus:border-4 focus:border-primary-100'}
                    key={i}
                    type="text"
                    maxLength={1}
                    value={pin[i]}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleKeyDown(e, i)}
                    ref={(el) => {
                      inputs.current[i] = el
                    }}
                    style={{ textAlign: 'center', marginRight: '0.5rem' }}
                />
            ))}
        </div>
  )
}

export default PinInput
