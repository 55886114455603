import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import CircularCompetencies from './competencies/circular-competencies'
import { useLocation } from 'react-router'
import { connect, useSelector } from 'react-redux'
import { handleGetUserQuizResults, handleGetUserSkills } from '../../../actions/user/common/Action'
import DosAndDonts from './dos-and-donts'
import Emotions from './emotions'
import QuizResult from '../../../components/public-components/nous-quiz/quiz-result'

function SkillsOverview ({ getUserSkills, getUserQuizResults }) {
  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()
  const skills = useSelector((state) => state.commonReducer.skills)
  const quizResults = useSelector((state) => state.commonReducer.quizResults)
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  useEffect(() => {
    setLoading(true)
    getUserSkills(pathname === '/dashboard/overview/self' ? 'self' : 'contact')
      .then(() => {
        setLoading(false)
        if (pathname === '/dashboard/overview/self') getUserQuizResults(userId)
      })
  }, [pathname])
  return (
        <div className={ 'bg-white-base rounded-lg p-2 md:p-[13px]' }>
            <Emotions/>
            <Spin spinning={ loading }>
                {
                    pathname === '/dashboard/overview/contact'
                      ? <div>
                            {
                                (skills?.aces.length === 0 && skills?.surges.length === 0 && skills?.sparks.length === 0)
                                  ? <div className={ 'text-gray-900 text-[25px] text-center p-20' }>
                                        You need feedbacks from at
                                        least 3 different contacts to
                                        see feedback-based profile.
                                    </div>
                                  : <div className={ 'mt-[30px]' }>
                                        <DosAndDonts/>
                                        {
                                            loading === false &&
                                            <CircularCompetencies/>
                                        }
                                    </div>
                            }
                        </div>
                      : <div className={ 'mt-[30px]' }>
                            <DosAndDonts/>
                            {
                                loading === false &&
                                <CircularCompetencies/>
                            }
                        </div>
                }
                {/* User Quiz Results */ }
                {
                  pathname === '/dashboard/overview/self' && quizResults.map((result, i) => <div key={ i }><QuizResult extraClasses={ 'w-full border my-5' }bulk={ true } result={ result }/></div>)
                }
            </Spin>
        </div>
  )
}

SkillsOverview.propTypes = {
  skills: PropTypes.array.isRequired,
  getUserQuizResults: PropTypes.func.isRequired,
  getUserSkills: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
  return {
    skills: state.commonReducer.allSkillNoPagination ?? []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserQuizResults: (userId) => dispatch(handleGetUserQuizResults(userId)),
  getUserSkills: (skillType) => dispatch(handleGetUserSkills(skillType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillsOverview)
