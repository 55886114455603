import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import logo from '../../assets/images/noustrobeta.svg'

function PublicHeader () {
  return (
    <>
      <div className="max-w-screen-2xl px-3 md:px-12 py-4 mx-auto border-b">
        <nav className="bg-white px-0 py-2">
          <div className="flex justify-between items-center">
            <div className={ 'cursor-pointer md:pl-0' }>
              <img src={ logo } width={ 130 } alt={ 'Nouscard' } height={ 'auto' }/>
            </div>
            <div>
              <Link to={ '/sign-in' }
                    className="text-gray-700 hover:text-gray-600 hover:bg-blue-200 px-4 py-2 rounded mr-4 font-medium">
                Login
              </Link>
              <Link to={ '/sign-up' }
                    className="bg-blue-50 text-white px-4 py-2 rounded text-blue-700 font-medium hover:bg-blue-500 hover:text-white-700">
                Register
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <Outlet/>
    </>
  )
}

export default PublicHeader
