import React from 'react'
// import React, { useState } from 'react'
import AuthLayout from './auth-layout'
import TlaFormWrapper from '../../components/tla-form-wrapper'
import { Form, Input } from 'antd'
// import { Form, Input, Radio } from 'antd'
import AuthBottomLink from './auth-bottom-link'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleAuthentication } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'
import '../../assets/css/custom.css'

function SignIn ({ authenticateUser }) {
  const location = useLocation()
  const fullPathWithParameters = location.pathname + location.search
  // alert(fullPathWithParameters !== '/sign-in' ? fullPathWithParameters : '/feed')
  return (<AuthLayout pageTitle={'Log in to your account'}>
            <div>
                <div>
                    <p className={'text-gray-500 text-base text-center mb-8'}>
                        Welcome back! Please enter your details.
                    </p>
                </div>
                <TlaFormWrapper
                    afterSubmit={fullPathWithParameters !== '/sign-in' ? fullPathWithParameters : '/dashboard/overview/self'}
                    buttonText={'Sign in'}
                    onSubmit={authenticateUser}>
                    <Form.Item name="email" label="Email"
                               rules={[{
                                 required: true, message: 'Email is Required'
                               }, {
                                 type: 'email', message: 'Enter a valid email'
                               }]}>
                        <Input size={'large'} placeholder={'Enter your email'}/>
                    </Form.Item>
                    <Form.Item name="password"
                               label={'Password'}
                               rules={[{ required: true, message: 'Password is required!' }, { min: 8, message: '' }]}>
                        <Input.Password
                            size={'large'}
                            type={'password'}
                            placeholder="••••••••"/>
                    </Form.Item>
                    <div className={'flex items-center justify-end mb-4'}>
                        <Link className={'text-primary-700'} to={'/forgot-password'}>Forgot Password</Link>
                    </div>
                </TlaFormWrapper>
                {/* <div className={'mb-3'}>
                    <a>
                        <Button size={'large'} className={'mt-4 flex justify-center items-center gap-x-3'} block>
                            <div className={'bg-google-icon h-6 w-6'}></div>
                            Sign up with Google
                        </Button>
                    </a>
                </div> */}
                <div className='mt-3'>
                    <AuthBottomLink
                        size={'sm'}
                        text={'Don’t have an account?'}
                        link={'/sign-up'} linkText={'Sign up'}/>
                </div>
            </div>
        </AuthLayout>)
}

SignIn.propTypes = {
  authenticateUser: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{authenticateUser: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (values) => dispatch(handleAuthentication(values))
  }
}

export default connect(null, mapDispatchToProps)(SignIn)
