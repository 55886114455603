import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { handleGetChat } from '../../../actions/user/chat/Action'
import { connect, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { useLocation } from 'react-router-dom'
import NewChatForm from '../chat-form'
import ChatHeader from './chat-header'
import ChatMessage from './chat-message'

function ChatContent ({ getChat, chatObj }) {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const { chatId } = useSelector(state => state.chatsReducer)
  const { expert, chat } = chatObj
  useEffect(() => {
    setLoading(true)
    getChat(location?.state?.chatId ?? chatId).then(() => setLoading(false)).catch(() => setLoading(false))
  }, [location])
  console.log(chatObj)
  return (
        <div className='py-1'>
          <Spin spinning={loading}>
            <ChatHeader date={location?.state?.date}/>
            <div className="content h-[calc(100vh_-_285px)] md:h-[calc(100vh_-_240px)]">
                {
                    chat?.map((item, index) => (
                        <ChatMessage key={index} expert={expert} text={item?.content} self={item.role === 'user'}/>
                    ))
                }
            </div>
            <NewChatForm loading={loading} setLoading={setLoading}/>
        </Spin>
        </div>
  )
}

ChatContent.propTypes = {
  getChat: PropTypes.func.isRequired,
  chatObj: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  chatObj: state.chatsReducer.chat
})

const mapDispatchToProps = (dispatch) => ({
  getChat: (chatId) => dispatch(handleGetChat(chatId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatContent)
