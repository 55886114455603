import React from 'react'
import TlaCircleProgress from './tla-circle-progress'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

function NextArrow (props) {
  // eslint-disable-next-line no-unused-vars,react/prop-types
  const { className, style, onClick } = props
  return (
        <svg className={`${className} right-[18px] -top-[48px]`} onClick={onClick} xmlns="http://www.w3.org/2000/svg"
             width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7.5 15L12.5 10L7.5 5" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
  )
}

function PrevArrow (props) {
  // eslint-disable-next-line react/prop-types
  const { className, onClick } = props
  return (
        <svg className={`${className} left-[unset] -top-[48px] right-[40px]`} onClick={onClick}
             xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.5 15L7.5 10L12.5 5" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
  )
}

function SkillSlider ({ data }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      }
    ]
  }
  return (
        <div className={'w-[320px] md:w-[700px] mx-auto'}>
            <Slider {...settings}>
                {
                    data?.map((skill) => (
                        <div key={skill.id} className={'mb-5 md:mb-0'}>
                            <TlaCircleProgress
                                absoluteScore={skill.absoluteScore}
                                normalizedScore={skill.normalizedScore}
                                title={skill?.skillTitle}/>
                        </div>
                    ))
                }
            </Slider>
        </div>
  )
}

SkillSlider.defaultProps = {
  data: []
}

SkillSlider.propTypes = {
  data: PropTypes.array
}

export default SkillSlider
