import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import TlaIcon from '../../../components/tla-icon'
import { FiMail, FiUser, FiUserPlus } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleAddContact } from '../../../actions/user/contacts/Action'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import ShareFeedback from '../../../components/share-feedback'
import TlaSelect from '../../../components/TlaSelect'

function SidebarForm ({ col, addContact, userId }) {
  const [form] = Form.useForm()
  const [errors, setErrors] = useState([])
  // const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const types = [
    {
      value: 'Family/Friends',
      label: 'Family/Friends'
    },
    {
      value: 'School',
      label: 'School'
    },
    {
      value: 'Work',
      label: 'Work'
    }
  ]
  const onFinish = (values) => {
    setLoading(true)
    values.feedbackUrl = 'requests/give-feedback'
    addContact(values).then((res) => {
      form.resetFields()
      TlaSuccess(res.data.data.message)
      setLoading(false)
    }).catch((error) => {
      TlaError(error.response.data.message ?? error.response.data?.data.message)
      setLoading(false)
    })
  }

  const onFinishFail = ({ errorFields }) => {
    const me = errorFields.map((item) => item.name[0])
    setErrors(me)
  }

  return (
        <Spin spinning={loading}>
            <div className={'rounded-lg bg-white-base p-4 min-w-[250px] basis-[250px] !h-fit shadow-3.5xl mb-[13px]'}>
                <div
                    className={`flex flex-${col} gap-2 mb-2
                 ${col === 'col' ? 'justify-center' : 'justify-start items-center'}`}>
                    <TlaIcon name={'user-plus'}/>
                    <h3 className={'font-medium text-sm text-gray-900'}>Add a contact</h3>
                </div>
                <Form
                      onFinishFailed={onFinishFail}
                      onFinish={onFinish}
                      form={form}
                      className={`flex items-center gap-5 flex-wrap md:flex-nowrap flex-${col}`}
                      layout={'vertical'}>
                    <Form.Item className='w-full my-0' name={'name'} rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}>
                        <Input prefix={<FiUser/>}
                               className={`${errors.includes('name') ? 'border-error-500' : ''}`}
                               size={'large'} placeholder={'Name'}/>
                    </Form.Item>
                    <Form.Item className='w-full my-0' name={'contactEmail'} rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}>
                        <Input prefix={<FiMail/>}
                               className={`${errors.includes('email') ? 'border-error-500' : ''}`}
                               size={'large'} placeholder={'Email'}/>
                    </Form.Item>
                    <div className='w-full border rounded-lg sidebar-contact-type'>
                      <FiUserPlus className='icon' />
                      <TlaSelect optionKey={'label'} placeholder={'Select Type'} name={'type'} required={true} customClass='w-full' optionClass={''} options={types} />
                    </div>
                    <Button htmlType={'submit'} size={'large'}
                            className={`${col === 'col' ? 'w-full' : 'w-[150px]'} btn btn-primary`}>
                        Add contact
                    </Button>
                </Form>

                {
                    col === 'col' &&
                    <div className={'mt-4'}>
                        <ShareFeedback/>
                    </div>
                }
            </div>
        </Spin>
  )
}

SidebarForm.defaultProps = {
  col: 'col'
}

SidebarForm.propTypes = {
  col: PropTypes.string,
  userId: PropTypes.number.isRequired,
  addContact: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id
})
const mapDispatchToProps = (dispatch) => ({
  addContact: (data) => dispatch(handleAddContact(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarForm)
