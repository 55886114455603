import React from 'react'
import Activity from '../../../assets/images/activity.svg'
import PlusCircle from '../../../assets/images/plus-circle.svg'
import { Dropdown } from 'antd'
import PropTypes from 'prop-types'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'

// eslint-disable-next-line react/prop-types
const SingleItem = ({ title, link }) =>
    <TlaAddNew link={ `/quick-action/${link}` }>
        <div>
            { title }
        </div>
    </TlaAddNew>

function FloatActions ({ isHeader }) {
  const items = [
    {
      key: '1',
      label: <SingleItem title={ 'Take Self Assessment' } link={'self-assessment'}/>
    },
    {
      key: '2',
      label: <SingleItem title={ 'Give Feedback' } link={'give-feedback'}/>
    },
    {
      key: '3',
      label: <SingleItem title={ 'Journal Emotion' } link={'journal-feedback'}/>
    }
  ]

  const menuStyle = {
    boxShadow: 'none'
  }

  // eslint-disable-next-line react/prop-types
  const MenuHeader = ({ menu }) => (
        <div className={ 'float-action w-[236px] bg-white-base rounded-lg py-3 shadow-lg' }>
            { React.cloneElement(menu, {
              style: menuStyle
            }) }
        </div>
  )
  return (
        <div>
            <div className={ 'hidden md:block' }>
                <Dropdown
                    trigger={ ['click'] } dropdownRender={ (menu) => <MenuHeader menu={ menu }/> } menu={ { items } }>
                    <button
                        style={ {
                          border: '1px solid var(--gradient-primary-60050090-deg, #7F56D9)'
                        } }
                        className={ 'py-2 px-3.5 rounded-lg cursor-pointer flex items-center gap-x-2' }>
                        <img src={ Activity } alt={ 'Actions' }/>

                        <p style={ {
                          background: 'var(--gradient-primary-60050090-deg, linear-gradient(90deg, #7F56D9 0%,' +
                                ' #9E77ED 100%)) !important',
                          backgroundClip: 'text',
                          '-webkit-background-clip': 'text',
                          '-webkit-text-fill-color': 'transparent'
                        } } className={ 'text-sm font-medium' }>
                            Quick Action
                        </p>
                    </button>
                </Dropdown>
            </div>
            <div className={ 'block md:hidden' }>
                <div className={ 'fixed right-10 bottom-20 md:bottom-16' }>
                    <Dropdown
                        trigger={ ['click'] } dropdownRender={ (menu) => <MenuHeader menu={ menu }/> }
                        menu={ { items } }>
                        <button
                            className={ 'p-3.5 rounded-full cursor-pointer focus:bg-transparent outline:bg-transparent active:bg-transparent' }
                            style={ {
                              background: 'var(--gradient-primary-60050090-deg,' +
                                    ' linear-gradient(90deg, #7F56D9' +
                                    ' 0%,' +
                                    ' #9E77ED 100%))'
                            } }>
                            <img src={ PlusCircle } alt={ 'Actions' }/>
                        </button>
                    </Dropdown>
                </div>
            </div>
        </div>
  )
}

FloatActions.defaultProps = {
  isHeader: false
}

FloatActions.propTypes = {
  isHeader: PropTypes.bool
}
export default FloatActions
