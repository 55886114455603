import React from 'react'
import CompetencyHead from './competency-head'
import { useSelector } from 'react-redux'
import SkillSlider from './skill-slider'

function CircularCompetencies () {
  const skills = useSelector((state) => state.commonReducer.skills)
  return (
        <div>
            <CompetencyHead/>
            {
                <div>
                    <h2 className="text-2xl mt-10">Aces</h2>
                    <p className="text-gray-400 mb-5">Finely tuned and highly proficient skills</p>
                    <SkillSlider data={skills?.aces ?? []}/>
                    <h2 className="text-2xl mt-10">Surges</h2>
                    <p className="text-gray-400 mb-5">Building on Basis towards greater competence</p>
                    <div>
                        <SkillSlider data={skills?.surges ?? []}/>
                    </div>
                    <h2 className="text-2xl mt-10">Sparks</h2>
                    <p className="text-gray-400 mb-5">Foundational skills are taking shape.</p>
                    <div>
                        <SkillSlider data={skills?.sparks ?? []}/>
                    </div>
                </div>

                // : <div className={'text-center'}>
                //       <p>
                //           You need to have received at least 3 feedbacks from your contacts to ensure anonymity
                //       </p>
                //   </div>
            }
        </div>
  )
}

export default CircularCompetencies
