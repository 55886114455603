import React, { useState } from 'react'
import Mail from '../../../../assets/images/icons/mail.svg'
import { Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { handleEmailVerification } from '../../../../actions/authenticate/Actions'
import Title from '../verification/title'
import PinInput from '../verification/pin-input'
import BackToLogin from '../verification/back-to-login'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Manual () {
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState(null)
  const handleComplete = (pin) => {
    setPin(pin)
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const onFinish = async () => {
    setLoading(true)
    dispatch(handleEmailVerification(pin)).then(() => {
      setLoading(false)
      navigate('/verify/verified', {
        state: {
          email: state?.email
        }
      })
    }).catch((error) => {
      console.log(error)
    })
  }
  return (
        <Spin spinning={loading} tip={'Please wait...'}>
            <div className={'h-screen flex items-center justify-start pt-20 flex-col bg-white-base'}>
                <img src={Mail} alt={'mail'}/>
                <div className={'mb-8'}>
                    <Title/>
                </div>
                <div className={'mb-8'}>
                    <PinInput length={4} onComplete={(handleComplete)}/>
                </div>
                <button onClick={onFinish}
                        className={'mb-8 bg-primary-400 border-primary-400 text-white-base text-base h-11 w-[360px] rounded-lg'}>
                    Verify email
                </button>
                <div className={'mb-8'}>
                    <p>Didn’t receive the email? <span className={'text-primary-700'}>Click to resend</span></p>
                </div>
                <BackToLogin/>
            </div>
        </Spin>
  )
}
